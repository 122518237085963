import styled, { css } from 'styled-components';

export const Container = styled.footer`
	padding: 40px;
	/* background-color: #fff; */
	background-color: #fcaf17;
	margin-top: 20px;
	width: 100%;
	cursor: default;
	flex-grow:1;
	max-height: 40%;
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;

	h3 {
		color: #fdae45;
		color: #fff;
		font-weight: 600;
		font-size: 1rem;
		margin-bottom: 7px;
	}

	a {
		color: #2c354a;
		border-bottom: 2px solid transparent;
		width: max-content;
		margin-bottom: 2px;
		transition: border-bottom 150ms ease-in-out;
		text-decoration: none;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 100%;

		&:hover {
			border-bottom: 2px solid #fdae45;
			transition: border-bottom 150ms ease-in-out;
		}
	}
`;

export const FollowUs = styled.div`
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	a {
		color: #55595c;
		color: #fff;
		transition: color 150ms ease-in-out;
		text-decoration: none;
		cursor: pointer;
		border: none !important;

		& + a {
			margin-left: 10px;
		}

		&:hover {
			transition: color 150ms ease-in-out;
			color: #fdae45;
		}
	}
`;

export const ImgLink = styled.a`
	margin-right: 10px;
	height: 25px;
	min-height: 25px;
	max-height: 25px;
	width: 100px;
	min-width: 100px;
	max-width: 100px;
	margin: 0 !important;
	overflow: hidden;

	${({ img: { src, size, position } }) => css`
		background-image: url(${src});
		background-repeat: no-repeat;
		background-position: ${position || 'center center'};
		background-size: ${size};
	`}
`;


export const Texto = styled.span`
	color: #fff;
`;