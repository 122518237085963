import styled from 'styled-components';

export const Title = styled.div`
	padding: 30px;
	margin-bottom: 20px;
	background-color: rgba(255, 255, 255, 0.85);
	color: #595a5d;
	width: 100%;
	cursor: default;

	h1 {
		margin-bottom: 10px;
		/* font-size: 22pt; */
		font-size: 20pt;
		color: #595a5d !important;
	}

	p {
		margin: 0;
		text-indent: 10pt;
	}
`;
