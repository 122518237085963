import produce from 'immer';
import * as constants from './constants';

const INITIAL_STATE = {
	presselected: null,
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case constants.SET_STATE:
			return produce(state, (draft) => {
				Object.keys(action.payload.data || {}).forEach((key) => {
					draft[key] = action.payload.data[key];
				});
			});
		default:
			return state;
	}
};

export default reducer;
