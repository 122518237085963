import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Jumbotron } from 'reactstrap';

import Footer from '../../Components/Footer';
import FormConsultaConstrucao from './FormConsultaConstrucao';
import FormConsultaInstalacao from './FormConsultaInstalacao';
import FormMemorialDescritivo from './FormMemorialDescritivo';
import FormCertidaoConfrontantes from './FormCertidaoConfrontantes';
import FormPlantaQuadra from './FormPlantaQuadra';
import FormConsultaAmbiental from './FormConsultaAmbiental';
import FormCertidaoEndereco from './FormCertidaoEndereco';

import { Aside, Content, Item, Section } from './styles';
import { setState } from '~/store/modules/documentsEmission/actions';

const Service = () => {
	const dispatch = useDispatch();
	const { presselected } = useSelector((e) => e.documentsEmission);

	const [selectedOption, setSelectedOption] = useState('');

	useEffect(() => {
		if (presselected) {
			setSelectedOption(presselected);
			dispatch(setState(null));
		}
	}, [dispatch,presselected]);


	return (
		<>
			<Content>
				<Aside>
					<h3>Documentos</h3>
				</Aside>
			</Content>
			<Footer />
		</>
	);
};

export default Service;
