import styled from 'styled-components';

export const Container = styled.div`
	width: 160px;
	height: 160px;
	min-width: 160px;
	min-height: 160px;
	padding: 10px;
	cursor: pointer;
	margin: 2px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	background-color: ${(p) => p.color};
	transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
	color: #595a5d;
	text-decoration: none !important;
	position: relative;
	font-family: 'Asap', sans-serif;

	&:hover {
		transform: scale(1.05);
		box-shadow: 0px 10px 26px -6px rgba(0, 0, 0, 0.75);
		transition: transform 150ms ease-in-out,
			box-shadow 150ms ease-in-out 55ms;
	}

	&:active {
		transform: scale(1);
		box-shadow: 0px 0px 0px 0px transparent;
		transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
	}

	h2 {
		/* font-size: 12pt; */
		font-size: 11pt;
		color: #595a5d;
		font-weight: 500;
		display:flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		text-align: center;
		justify-content: space-evenly;
		height: 100%;

		p {
			margin: 0;
		}
	}

	span {
		font-size: 10pt;
		color: #2c354a;
		text-transform: uppercase;
	}

	div {
		flex: 1;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
`;
