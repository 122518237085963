import React from 'react';
import { Row, Col } from 'reactstrap';

import {
	FaFacebookSquare,
	FaInstagram,
	FaYoutube,
	FaWhatsapp
} from 'react-icons/fa';

import { Container, Item, FollowUs, ImgLink,Texto } from './styles';

import logo_prefeitura from '~/assets/img/logotipo.png';

const Footer = () => {
	return (
		<Container>
			<Row>
				<Col md="4">
					<Item className="mb-3">
						<h3>PREFEITURA MUNICIPAL DE ITABIRA</h3>
						<a
							href="https://www.itabira.mg.gov.br/organograma"
							target="_blank"
							rel="noopener noreferrer"
						>
							Órgãos Municipais
						</a>
						<a
							href="https://www.itabira.mg.gov.br/detalhe-da-materia/info/legislacao/164393"
							target="_blank"
							rel="noopener noreferrer"
						>
							Leis municipais
						</a>
						<a
							href="https://servicositabira.govbr.cloud/pronimtb/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Transparência
						</a>
						<a
							href="https://www.itabira.mg.gov.br/detalhe-da-materia/info/ouvidoria/235382"
							target="_blank"
							rel="noopener noreferrer"
						>
							Ouvidoria
						</a>
					</Item>
				</Col>
				<Col md="2">
					<Item className="mb-3">
						<h3>GESTÃO TERRITORIAL (SIT)</h3>
						<a
							href="/article/gestaoterritorial"
							target="_blank"
							rel="noopener noreferrer"
						>
							Sobre
						</a>
					</Item>
				</Col>
				<Col md="3">
					<Item className="mb-3">
						<h3>LOCALIZAÇÃO</h3>
						<Texto>
						Avenida Carlos de Paula Andrade, nº 135, Centro.
						</Texto>
					</Item>
					<Item className="mb-3">
						<h3>HORÁRIO DE FUNCIONAMENTO</h3>
						<Texto>
							Segunda a Sexta - 07:00 às 17:00.
						</Texto>
					</Item>					
					<Item className="mb-3">
						<h3>TELEFONE PARA CONTATO</h3>
						<Texto>
						(31) 3839-2000
						</Texto>
					</Item>
				</Col>
				<Col md="3">
					<Item className="mb-3">
						<h3>SIGA-NOS</h3>
						<FollowUs>
							<ImgLink
								href="https://www.itabira.mg.gov.br/"
								target="_blank"
								rel="noopener noreferrer"
								alt="Prefeitura Municipal de Itabira"
								title="Prefeitura Municipal de Itabira"
								img={{
									src: logo_prefeitura,
									size: '100%',
								}}
							/>
						</FollowUs>
						<FollowUs>
							<a
								href="https://instagram.com/prefeitura_itabira?igshid=YmMyMTA2M2Y=%20title="
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaInstagram size={24} />
							</a>
							<a
								href="https://www.facebook.com/prefeituraitabira"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaFacebookSquare size={24} />
							</a>
							<a
								href="https://wa.me/message/GHCYQKLTCAC2B1"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaWhatsapp size={24} />
							</a>							
							<a
								href="https://www.youtube.com/channel/UCDwGtRkNipr7h_r0bKSIuyw"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaYoutube size={24} />
							</a>							
						</FollowUs>
					</Item>
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;
