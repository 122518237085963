import React from 'react';

export default [
	{
		name: 'Gestão Territorial',
		id: 'gestaoterritorial',
		content: (
			<>
				<p>
					O sistema é composto por um software voltado à Gestão
					Territorial - SIT (disponível em&nbsp;
					<a
						href="https://itabira.geowise.com.br/"
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: '#0072ff' }}
					>
						https://itabira.geowise.com.br/
					</a>
					) e um GeoPortal (disponível em&nbsp;
					<a
						href="http://itabira-geoportal.geowise.com.br/"
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: '#0072ff' }}
					>
						https://itabira-geoportal.geowise.com.br/
					</a>
					), que apresentam customizações necessárias para atender às
					necessidades de manutenção e visualização integrada das
					bases de dados Geoespaciais do Cadastro Territorial do
					município de Itabira.
				</p>
				<p>
					As etapas que contemplaram o projeto foram: Planejamento do
					projeto; Implantação do&nbsp;
					<a
						href="https://itabira.geowise.com.br/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Sistema de Gestão Territorial - SIT
					</a>
					; Customização; Serviços de criação de banco de dados e
					migração; Serviços de treinamento; Manuais do sistema e
					Serviços de suporte técnico e manutenção.
				</p>
				<p>
					O &nbsp;
					<a
						href="https://itabira.geowise.com.br/"
						target="_blank"
						rel="noopener noreferrer"
					>
						sistema de Gestão Territorial - SIT
					</a>
					&nbsp;é composto por Módulos de Cadastro de Logradouros, de
					Zoneamentos, de Parcelas Territoriais, de Unidades
					Autônomas, de Infraestrutura Urbana, de Avaliações e
					Auditoria. O sistema é utilizado e gerido por grande parte
					das secretarias e órgãos municipais. As informações são atualizadas diariamente pelo setor da
					administração municipal responsável.
				</p>

				<p>
					Dentre várias funcionalidades para uso interno da
					prefeitura, o sistema também fornece serviços ao cidadão,
					através do GeoPortal, objetivando desburocratização: como
					consultas de viabilidade automatizadas, emissão de certidões
					e outros documentos oficiais. Além disso, é um instrumento
					de transparência pública da gestão municipal.
				</p>
				<p>
					A Infraestrutura é baseado em tecnologias livres, contém mapas
					temáticos, ortofotos e ortomosaicos integrados com dados
					vetoriais e alfanuméricos. Permite a manutenção da base
					cartográfica oficial do município, sincronizada com base de
					dados alfanumérica, mantendo a integridade e qualidade dos
					dados disponíveis. Além disso, o sistema permite realizar o
					cadastro de objetos territoriais, feições, análises
					espaciais e geração de mapas para impressão.
				</p>

				<p>
					<strong>Sobre o Sistema</strong>
				</p>

				<p>
					O projeto utiliza as linguagens de back-end denominada Ruby,
					associada ao framework Ruby on Rails, e recursos de
					programação front-end HTML5, CSS, Java Script, ExtJS e Open
					Layers e como ambiente de desenvolvimento foi utilizado o
					Microsoft Visual Studio Code, para a ferramenta de cadastro.
				</p>

				<p>
					<strong>Características técnicas da plataforma Web</strong>
				</p>

				<p>Sistema Operacional (SO): Linux Ubuntu Server 20.04 LTS.</p>

				<p>Software de Geoprocessamento Web: Geoserver</p>

				<p>Software de Geoprocessamento Desktop: QGIS</p>

				<p>
					Gerenciador de Banco de Dados: software PostgreSQL® com o
					PostGIS®
				</p>

				<p>Software de Banco de Dados: PG Admin IV e DBeaver</p>

				<p>Banco de Dados: PostgreSQL</p>

				<p>Extensão Espacial: PostGIS</p>

				<p>
					Browser Suportados: Microsoft Edge 15, Google Chrome build
					60.0.3112.112, Mozilla Firefox 54, Safari 11.0.3 ou
					superiores.
				</p>
			</>
		),
	},
	// {
	// 	name: 'Diretoria de Gestão Territorial',
	// 	id: 'diretoria_gestão_territorial',
	// 	content: (
	// 		<>
	// 			<p>
	// 				A Diretoria de Gestão Territorial (DGT) do Instituto de
	// 				Pesquisa e Planejamento Urbano de Florianópolis (IPUF) é
	// 				responsável pela produção, manutenção e disponibilização de
	// 				dados e informações territoriais oficiais do município.
	// 				Implementa a gestão do território através da geoinformação
	// 				oficial, cadastro territorial e gestão do patrimônio
	// 				imobiliário municipal.
	// 			</p>

	// 			<p>
	// 				<strong>Principais áreas de atuação:</strong>
	// 			</p>

	// 			<dl>
	// 				<dd>✔ Cadastro Territorial;</dd>
	// 				<dd>✔ Geoprocessamento;</dd>
	// 				<dd>✔ Cartografia;</dd>
	// 				<dd>✔ Pesquisa;</dd>
	// 				<dd>✔ Patrimônio imobiliário;</dd>
	// 			</dl>

	// 			<p>
	// 				A DGT é composta por duas gerências, Gerência de Gestão
	// 				Territorial e Geoinformação e Gerência de Patrimônio
	// 				Imobiliário e três departamentos, Departamento de Cadastro e
	// 				Base Territorial – DCBT, Departamento de Geoinformação e
	// 				Pesquisa – DGP e Departamento de Controle do Patrimônio
	// 				Imobiliário – DCPI. Atualmente é composta pelos seguintes
	// 				membros:
	// 			</p>

	// 			<ul>
	// 				<li>Kaliu Teixeira - Diretor de Gestão Territorial</li>
	// 				<li>
	// 					Giovanni Ribeiro - Gerente de Patrimônio Imobiliário
	// 				</li>
	// 				<li>
	// 					Jonatas Rafael dos Santos - Gerente de Gestão
	// 					Territorial e Geoinformação
	// 				</li>
	// 				<li>Alexandre Felix - Geógrafo</li>
	// 				<li>Altair Tomaz Nunes - Técnico em Agrimensura</li>
	// 				<li>Bianca Ana Coelho - Arquiteta e Urbanista</li>
	// 				<li>Camila Mayara Rabaioli - Secretária</li>
	// 				<li>Danielli Medeiros - Secretária</li>
	// 				<li>Dejair Lima - Técnico em Edificações</li>
	// 				<li>Diego Goes - Técnico</li>
	// 				<li>Elisa Bezerra Cabral - Geógrafa</li>
	// 				<li>Flávio Schons - Arquiteto e Urbanista</li>
	// 				<li>Gilberto Pessoa Costa - Desenhista</li>
	// 				<li>Juliana Amalia de Oliveira Britto - Bibliotecária</li>
	// 				<li>Leandro Lino Freitas - Geólogo</li>
	// 				<li>Luana Lenzi - Engenheira Civil</li>
	// 				<li>
	// 					Luiz Carlos Martins de Andrade - Técnico em Edificações
	// 				</li>
	// 				<li>Valtair Hercilio da Silva - Desenhista</li>
	// 				<li>Bernardo Campos - Estagiário em Geografia</li>
	// 				<li>Filipe Hopers - Estagiário em Geografia</li>
	// 			</ul>
	// 		</>
	// 	),
	// },
	// {
	// 	name: 'Comissão Municipal de Geoinformação',
	// 	id: 'comissao_municipal_geoinformacao',
	// 	content: (
	// 		<>
	// 			<p>
	// 				A Comissão Municipal de Geoinformação (CMGEO) foi instituída
	// 				pelo DECRETO N. 20.990, DE 16 DE DEZEMBRO DE 2019. A CMGEO é
	// 				constituída por representantes indicados pelos órgãos e
	// 				secretarias da administração direta e indireta. Atualmente a
	// 				CMGEO é coordenada pelo Geógrafo Kaliu Teixeira,
	// 				representante da Diretoria de Cadastro, Geoprocessamento e
	// 				Pesquisa do Instituto de Pesquisa e Planejamento Urbano de
	// 				Florianópolis - IPUF, Gerente de Cadastro, Geoprocessamento
	// 				e Pesquisa deste setor.
	// 			</p>
	// 			<p>
	// 				Os membros da CMGEO são representantes do Instituto de
	// 				Pesquisa e Planejamento Urbano de Florianópolis, da
	// 				Secretaria Municipal de Infraestrutura, da Secretaria
	// 				Municipal da Fazenda, da Secretaria Municipal de
	// 				Infraestrutura, da Secretaria Municipal do Continente, da
	// 				Secretaria Municipal de Desenvolvimento Urbano, da Fundação
	// 				Municipal do Meio Ambiente e da Universidade Federal de
	// 				Santa Catarina.
	// 			</p>
	// 			<p>
	// 				<strong>A CMGEO tem os seguintes objetivos:</strong>
	// 			</p>

	// 			<dl>
	// 				<dd>
	// 					I - Acompanhar a execução do Contrato 1066/IPUF/2019;
	// 				</dd>

	// 				<dd>
	// 					II - Propor as diretrizes, normas e padrões técnicos
	// 					para execução da Política Municipal de Geoinformação;
	// 				</dd>
	// 				<dd>
	// 					III - Acompanhar a implementação, manutenção,
	// 					padronização, atualização e inserção de dados no Sistema
	// 					de Gestão Territorial municipal, visando à sua
	// 					utilização com os diversos sistemas operacionais e
	// 					aplicativos de geoprocessamento, inclusive os livres e
	// 					de código aberto;
	// 				</dd>
	// 				<dd>
	// 					IV - Propor as regras de atuação para os órgãos e entes
	// 					municipais na produção, manutenção, atualização de
	// 					dados, com o objetivo de evitar redundâncias e
	// 					duplicidades;
	// 				</dd>
	// 				<dd>
	// 					V - Apresentar a necessidade de contratação de
	// 					consultorias técnicas e celebração de convênios com
	// 					órgãos públicos ou entes privados;
	// 				</dd>
	// 				<dd>
	// 					VI - Propor programas de treinamento com a finalidade de
	// 					capacitar recursos humanos para o uso das ferramentas de
	// 					Geoinformação no âmbito municipal;
	// 				</dd>
	// 				<dd>
	// 					VII - Propor medidas operacionais visando garantir, no
	// 					âmbito dos órgãos e entes municipais, a divulgação dos
	// 					resultados e dos avanços obtidos pelo uso do Sistema de
	// 					Gestão Territorial municipal;
	// 				</dd>
	// 			</dl>

	// 			<p>
	// 				<strong>A CMGEO possui as seguintes atribuições:</strong>
	// 			</p>

	// 			<dl>
	// 				<dd>
	// 					l - Fiscalizar a execução do Contrato 1066/IPUF/2019;
	// 				</dd>
	// 				<dd>
	// 					Il - Elaborar relatórios técnicos de acompanhamento das
	// 					atividades desenvolvidas no decorrer do Contrato
	// 					1066/IPUF/2019;
	// 				</dd>
	// 				<dd>
	// 					lll - Anotar em registro próprio todas as ocorrências
	// 					relacionadas com a execução do Contrato 1066/IPUF/2019,
	// 					indicando dia, mês e ano, bem como o nome das pessoas
	// 					eventualmente envolvidas, determinando o que for
	// 					necessário à regularização das faltas ou defeitos
	// 					observados e encaminhando os apontamentos à autoridade
	// 					competente para as providências cabíveis.
	// 				</dd>
	// 				<dd>
	// 					IV - As decisões e providências que ultrapassarem a
	// 					competência da CMGEO deverão ser solicitadas ao
	// 					Superintende do IPUF em tempo hábil, para a adoção das
	// 					medidas convenientes.
	// 				</dd>
	// 				<dd>
	// 					V - Validar a inserção dos dados e informações temáticas
	// 					que serão disponibilizadas no Sistema de Gestão
	// 					Territorial;
	// 				</dd>
	// 				<dd>
	// 					VI - Elaborar estudos técnicos para diagnosticar a base
	// 					de dados cartográfica e cadastral do município;
	// 				</dd>
	// 				<dd>
	// 					VII - Recomendar a contratação de novos produtos e
	// 					serviços relacionados a Geoinformação municipal;
	// 				</dd>
	// 				<dd>
	// 					VIII - Realizar reuniões periódicas a fim de executar as
	// 					atividades necessárias para executar a Política
	// 					Municipal de Geoinformação.
	// 				</dd>
	// 			</dl>
	// 		</>
	// 	),
	// },
	// {
	// 	name: 'História do Geoprocessamento',
	// 	id: 'historia_do_geoprocessamento',
	// 	content: (
	// 		<>
	// 			<p>
	// 				O geoprocessamento corporativo tem como objetivos a evolução
	// 				e o desenvolvimento institucional do município de
	// 				Florianópolis através da modernizações da gestão cadastral,
	// 				territorial e temática em um ambiente integrado envolvendo
	// 				cadastro técnico urbano e geoprocessamento, formando uma
	// 				infra-estrutura de geoinformação unificada, com o
	// 				compartilhamento, acessibilidade e transparência, visando
	// 				também a criação dos processos de manipulação, monitoramento
	// 				e fiscalização com a necessária permeabilidade setorial das
	// 				bases de informações e a integração das secretarias
	// 				municipais, bem como o acesso seletivo ao contribuinte.
	// 			</p>
	// 			<p>
	// 				O município de Florianópolis frente à dinâmica de suas
	// 				transformações através de sua expansão urbana e crescimento
	// 				regional os quais imprimem pressão sobre o meio natural e
	// 				sócio-econômico, incrementa a demanda por serviços e
	// 				equipamentos públicos fundamentais para a manutenção da
	// 				qualidade de vida de sua população.
	// 			</p>
	// 			<p>
	// 				Assim o conhecimento da realidade do município é a base
	// 				fundamental para os processos de tomadas de decisão, sendo
	// 				necessária a reestruturação de seu modelo de gestão e
	// 				planejamento através da mudança de paradigma nos processos
	// 				administrativos.
	// 			</p>
	// 			<p>
	// 				O Cadastro Técnico Urbano, de caráter territorial e
	// 				sócio-econômico, é a principal base de informações em que
	// 				estão suportados os processos de arrecadação tributária,
	// 				planejamento, ordenamento territorial e fiscalização, bem
	// 				como para outras ações setoriais como nas áreas da saúde,
	// 				habitação, meio ambiente e infra-estrutura, fundamental como
	// 				fonte de dados não apenas à apropriação de recursos fiscais,
	// 				mas também, como ferramenta de análise e suporte para
	// 				tomadas de decisão.
	// 			</p>

	// 			<p>
	// 				A formação de uma infra-estrutura de geoinformação
	// 				integrada, com o compartilhamento e permeabilidade setorial
	// 				das informações cadastrais e temáticas, através de técnicas
	// 				de geoprocessamento cria condições para a construção de um
	// 				modelo de gestão territorial moderno com capacidade de
	// 				monitoramento e análise, possibilitando a atualização
	// 				permanente, manipulação e recuperação às informações.
	// 			</p>

	// 			<p>
	// 				<strong>HISTÓRICO</strong>
	// 			</p>

	// 			<p>
	// 				<strong>Base Cartográfica</strong>
	// 			</p>

	// 			<p>
	// 				Até o ano de 1996 o município de Florianópolis, dispunha das
	// 				seguintes informações cartográficas, sem a estas se limitar,
	// 				quais sejam:
	// 			</p>

	// 			<ul>
	// 				<li>
	// 					Cobertura aerofotogramétrica preto e branco, na escala
	// 					1:20.000, executada no ano de 1938;
	// 				</li>

	// 				<li>
	// 					Cobertura aerofotogramétrica preto e branco, na escala
	// 					1:25.000, executada no ano de 1957;
	// 				</li>
	// 				<li>
	// 					Cobertura aerofotogramétrica preto e branco, na escala
	// 					1:20.000, executada no ano de 1969;
	// 				</li>
	// 				<li>
	// 					Cobertura aerofotogramétrica preto e branco, na escala
	// 					1:25.000, executada no triênio 1976/1978;
	// 				</li>
	// 				<li>
	// 					Cobertura aerofotogramétrica preto e branco, na escala
	// 					1:8.000 e 1:25.000, executada no ano de 1994;
	// 				</li>
	// 				<li>
	// 					Rede de referência geodésica do município de
	// 					Florianópolis.
	// 				</li>
	// 			</ul>

	// 			<p>
	// 				Apesar da existência de todas estas coberturas realizadas a
	// 				partir da década de 1930, somente no ano de 1979, o
	// 				município veio a produzir a sua primeira base cartográfica a
	// 				partir da cobertura aérea na escala de 1:25.000. Tal base
	// 				cartográfica foi realizada na escala de 1:10.000 com curvas
	// 				de nível eqüidistantes de 5 metros, sob a forma analógica.
	// 			</p>

	// 			<p>
	// 				No período de 1979 até 1996, foi esta a única base
	// 				cartográfica disponível no município, tendo a mesma ampla
	// 				utilização na elaboração de projetos de sistemas viários,
	// 				definição de plano diretor, etc.
	// 			</p>

	// 			<p>
	// 				A partir do ano de 1997, tendo em vista a desatualização da
	// 				base cartográfica produzida em 1979, o Município de
	// 				Florianópolis iniciou um programa cartográfico digital na
	// 				escala de 1:2.000.
	// 			</p>

	// 			<p>
	// 				No período de 1997 até 2004, foram mapeados por processo de
	// 				restituição digital na escala de 1:2.000, todos os distritos
	// 				do município, excetuando-se o Distrito Sede.
	// 			</p>

	// 			<p>
	// 				Adicionalmente foram confeccionadas as ortofotocartas
	// 				digital na escala de 1:2.000, dos distritos: Lagoa da
	// 				Conceição, Barra da Lagoa, Ribeirão da Ilha, Pântano do Sul,
	// 				Campeche, Canasvieiras, Cachoeira do Bom Jesus e Ratones,
	// 				bem como foram confeccionadas as ortofotocartas na escala
	// 				1:5.000, a partir de cobertura aérea colorida na escala de
	// 				1:15.000, de todo o município no ano de 2002.
	// 			</p>

	// 			<p>
	// 				A rede de referência Geodésica do município de Florianópolis
	// 				é composta por 121 (cento e vinte e uma) estações,
	// 				distribuídas em todos os distritos, exceto no Distrito Sede.
	// 				Para a obtenção das coordenadas desta rede foi utilizado o
	// 				processo de Posicionamento Global por Satélite (GPS),
	// 				amarrado a rede clássica do IBGE, tendo-se utilizados das
	// 				estações Base Aérea (Florianópolis) e SG-10-2252 (Governador
	// 				Celso Ramos). Para a obtenção das altitudes ortométricas das
	// 				estações componentes da Rede de Referência Geodésica, foi
	// 				utilizado o processo de nivelamento geométrico amarrado em
	// 				referências de nível da rede de 1° ordem do IBGE.
	// 			</p>

	// 			<p>
	// 				<strong>Cadastro Técnico Urbano</strong>
	// 			</p>

	// 			<p>
	// 				O Cadastro Técnico Urbano foi implantado em 1982, através de
	// 				um projeto de gestão da área conurbada dos municípios de
	// 				Florianópolis, Biguaçu, São José e Palhoça, denominado como
	// 				AUF - Aglomerado Urbano de Florianópolis. Foi criado um
	// 				modelo cadastral para ser utilizado nestes municípios
	// 				compreendendo uma nomenclatura de inscrição cadastral
	// 				composta por: distritos, setores, quadras e lotes, adotando
	// 				uma numeração cartesiana iniciando em 1 até 100, começando
	// 				do norte/oeste (em Biguaçu) indo em direção sul.
	// 			</p>

	// 			<p>
	// 				Desta forma, seguindo uma sistemática foram criados em
	// 				Florianópolis 31 distritos cadastrais com numeração variando
	// 				entre 17 e 85, articulados cada um em 4 pranchas na escala
	// 				1:5.000 tendo como base uma cartografia na escala 1:10.000
	// 				(de 1979), e cada qual divididos em 100 setores
	// 				(quadrantes). Partindo-se para um detalhamento maior foram
	// 				criadas plantas de quadra sob a nomenclatura de DDSSQQQ
	// 				(distrito, setor, quadra), através de levantamentos em campo
	// 				por topografia e a restituição de 1979. Nestas, foram
	// 				transcritas informações do cadastro, tais como número do
	// 				lote, número de correspondência, nomes e códigos/seções de
	// 				logradouros.
	// 			</p>

	// 			<p>
	// 				Esta base gráfica carece de precisão cartográfica para
	// 				representar o cadastro técnico urbano atual frente às suas
	// 				transformações sofridas ao longo do tempo, em que a
	// 				interação entre estas bases (gráfica e literal), tem um
	// 				frágil elo de ligação, amparado por processos manuais de
	// 				sincronismo e atualização geométrica sem georeferência,
	// 				dificultando uma gestão cadastral adequada às necessidades
	// 				do município.
	// 			</p>

	// 			<p>
	// 				Na década de 90, foram efetuados recadastramentos
	// 				imobiliários, procurando-se manter atualizadas essas bases
	// 				de informações. Em 2003 foi efetuado um comparativo do
	// 				crescimento da base cadastral entre os anos 1994, 1998 e
	// 				2003, resultando numa avaliação da perda em arrecadação na
	// 				ordem de 2% a.a. e em área construída 6% a.a.
	// 			</p>

	// 			<p>
	// 				Em 2005 foi lançado o PAC - Programa de Atualização
	// 				Cadastral, visando atualizar de forma sistêmica a base de
	// 				informações cadastrais, proporcionando uma visão real da
	// 				situação da ocupação urbana do município.
	// 			</p>

	// 			<p>
	// 				<strong>Geoprocessamento</strong>
	// 			</p>

	// 			<p>
	// 				Em 2003 o Município desenvolveu seu primeiro projeto de
	// 				geoprocessamento, integrante do PMAT -Programa de
	// 				Modernização da Administração Tributária, sendo seu
	// 				principal foco a estruturação do cadastro técnico, contendo
	// 				informações da base cartográfica digital e codificação das
	// 				informações do cadastro imobiliário.
	// 			</p>

	// 			<p>
	// 				O projeto previu a estruturação da base cartográfica digital
	// 				para ambiente SIG - Sistema de Informações Geográficas - e
	// 				seu relacionamento com os dados literais do cadastro
	// 				imobiliário, abrangendo a área sul do município (Distritos
	// 				de Campeche, Ribeirão da Ilha e Pântano do Sul), sendo
	// 				objeto de pesquisa, análise, cruzamento de informações.
	// 			</p>

	// 			<p>
	// 				O Geoprocessamento Corporativo foi desenvolvido objetivando
	// 				capacitar institucionalmente e aplicar soluções tecnológicas
	// 				visando promover a modernização da Administração Municipal
	// 				através de sua Gestão e Fiscalização Cadastral, da
	// 				unificação e integração das Bases Territoriais, do Cadastro
	// 				Técnico ao Geoprocessamento.
	// 			</p>

	// 			<p>
	// 				Após a aquisição e adequação da base de informações do
	// 				município todo o produto final é disponibilizado no Portal
	// 				Geoprocessamento Corporativo. As informações são
	// 				apresentadas num ambiente totalmente desenvolvido em
	// 				tecnologia de software Livre, onde são apresentados acervos
	// 				de imagens e mapas temáticos.
	// 			</p>

	// 			<p>
	// 				Através do uso de mapas temáticos a administração agiliza os
	// 				processos de atendimento ao cidadão. Esta ferramenta é
	// 				potencializada através do uso em conjunto com as ortofotos
	// 				disponibilizadas pelo sistema, sendo possível a impressão
	// 				via internet.
	// 			</p>

	// 			<p>
	// 				<strong>
	// 					Os produtos os quais o usuário tem acesso no portal são:
	// 				</strong>
	// 			</p>

	// 			<ul>
	// 				<li>
	// 					<p>a) Acervo de Fotos Aéreas do Município:</p>
	// 					<ul>
	// 						<li>
	// 							Mosaicos fotogramétricos de 1938, 1957, 1977, e
	// 							1994;
	// 						</li>

	// 						<li>
	// 							- Ortofotos de 2000, 2001, 2002, 2003 e 2007.
	// 						</li>
	// 					</ul>
	// 				</li>
	// 				<li>
	// 					<p>b) Cartografia Temática:</p>
	// 					<ul>
	// 						<li>Declividade;</li>
	// 						<li>Hipsometria;</li>
	// 						<li>Distritos Cadastrais;</li>
	// 						<li>Bairros;</li>
	// 						<li>UEP Unidade Espacial de Planejamento;</li>
	// 						<li>Censo 2000;</li>
	// 						<li> Regionais de Saúde;</li>
	// 						<li>Perímetro Urbano;</li>
	// 						<li> Zoneamento Urbano;</li>
	// 						<li>Condicionantes Ambientais;</li>
	// 						<li>Situação de Ocupação do Território;</li>
	// 						<li>Situação de Uso do Território;</li>
	// 						<li>Cadastro Técnico Urbano.</li>
	// 					</ul>
	// 				</li>
	// 				<li>
	// 					<p>c) Facilidades de Impressões</p>
	// 					<ul>
	// 						<li>Impressão de mapas na internet;</li>
	// 						<li>Impressão de plantas de quadra;</li>
	// 						<li>Croquis;</li>
	// 						<li>Pré-consulta de Viabilidade;</li>
	// 						<li>
	// 							Certificação Digital de Documentos Impressos.
	// 						</li>
	// 					</ul>
	// 				</li>
	// 			</ul>
	// 		</>
	// 	),
	// },
];
