export const emailInfos = [
	{ subject: 'Geoportal', recipient: '' },
	// {
	// 	subject:
	// 		'Região continental - Pré-consulta de viabilidade para instalação',
	// 	recipient: 'patriciapd@pmf.sc.gov.br',
	// },
	// {
	// 	subject: 'Região continental - Pré-consulta para fins de construção',
	// 	recipient: 'patriciapd@pmf.sc.gov.br',
	// },
	// {
	// 	subject: 'Pré-consulta de viabilidade para instalação',
	// 	recipient: 'gabinete.smdu@pmf.sc.gov.br',
	// },
	// {
	// 	subject: 'Pré-consulta para fins de construção',
	// 	recipient: 'viabilidade.planodiretor@pmf.sc.gov.br',
	// },
	// {
	// 	subject: 'Pré-consulta ambiental',
	// 	recipient: 'delic.floram@pmf.sc.gov.br',
	// },
	// {
	// 	subject: 'Certidão de confrontantes',
	// 	recipient: 'gti.smf@pmf.sc.gov.br',
	// },
	{ subject: 'Download', recipient: '' },
	{ subject: 'Geoserviços', recipient: '' },
	{ subject: 'Outros', recipient: '' },
];
