import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { VscLoading } from 'react-icons/vsc';
import { IoIosArrowDown } from 'react-icons/io';
import * as Validation from 'availity-reactstrap-validation';
import * as Reactstrap from 'reactstrap';

import { emailInfos } from '~/config/help';
import Footer from '~/Components/Footer';
import * as searcActions from '~/store/modules/search/actions';
import * as helpActions from '~/store/modules/help/actions';
import * as Styles from './styles';

const Help = () => {
	const dispatch = useDispatch();
	const { data, done } = useSelector((e) => e.search);
	const { loading, success, error } = useSelector((e) => e.help);

	const [searchText, setSearchText] = useState('');

	const [state, applyState] = useState({
		reportName: '',
		reportEmail: '',
		reportPhone: '',
		reportMessage: '',
		subject: '',
		recipient: '',
	});

	function handleSendEmail() {
		dispatch(
			helpActions.sendEmailResquest({
				...state,
				sender: process.env.REACT_APP_GEOPORTAL_EMAIL,
			})
		);
	}

	function setState(value) {
		if (!value) {
			const newState = {};
			Object.keys(state).forEach((e) => {
				newState[e] = '';
			});

			applyState(newState);
		} else {
			applyState({ ...state, ...value });
		}
	}

	return (
		<>
			<Styles.Content>
				<Styles.Search>
					<h1>Encontre os serviços do GEOPORTAL</h1>

					<Styles.InputSearch
						style={{ maxWidth: '500px' }}
						type="search"
						placeholder="DIGITE O SERVIÇO DESEJADO AQUI"
						value={searchText}
						onChange={({ target: { value } }) => {
							dispatch(searcActions.searchResquest(value));

							setSearchText(value);
						}}
					/>

					<small>
						Busque pelo nome do serviço, como
						<span style={{ textDecoration: 'underline' }}>
							DOCUMENTO 1
						</span>
						ou
						<span style={{ textDecoration: 'underline' }}>
							DOCUMENTO 2
						</span>
					</small>
				</Styles.Search>
				<Styles.Box>
					{done && (
						<Styles.ResultsTitle>
							RESULTADO DE BUSCA PARA &quot;{searchText || ' '}
							&quot;
						</Styles.ResultsTitle>
					)}

					{data[0] && done && (
						<>
							<Reactstrap.Table borderless hover>
								<tbody>
									{data.map((e, i) => (
										<tr key={String(i)}>
											<td>
												<a
													href={e.link}
													target="_blank"
													rel="noopener noreferrer"
												>
													{e.label}
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</Reactstrap.Table>
							<Styles.Hr />
						</>
					)}

					<Validation.AvForm
						onValidSubmit={handleSendEmail}
						style={{ paddingTop: data[0] && done ? '20px' : '0' }}
					>
						<Styles.ContactTitle>
							<p>CONTATO</p>
							<small>
								Entre em contato conosco e tire sua dúvida
							</small>
						</Styles.ContactTitle>
						{!success && !error ? (
							<>
								<Validation.AvGroup>
									<Reactstrap.UncontrolledDropdown>
										<Reactstrap.DropdownToggle
											tag="button"
											type="button"
											className="form-control text-left d-flex align-items-center justify-content-between"
										>
											<span className="mr-1 text-truncate">
												{state.subject ? (
													<>
														<strong>
															Assunto:{' '}
														</strong>
														{state.subject}
													</>
												) : (
													'Escolha um assunto'
												)}
											</span>
											<IoIosArrowDown
												size={12}
												style={{
													minWidth: '12px',
													minHeight: '12px',
												}}
											/>
										</Reactstrap.DropdownToggle>
										<Reactstrap.DropdownMenu className="w-100">
											{emailInfos.map((e) => (
												<Reactstrap.DropdownItem
													className="text-wrap"
													onClick={() => setState(e)}
												>
													{e.subject}
												</Reactstrap.DropdownItem>
											))}
										</Reactstrap.DropdownMenu>
									</Reactstrap.UncontrolledDropdown>
									<Validation.AvInput
										required
										type="hidden"
										name="email_subject"
										id="email_subject"
										value={state.subject}
									/>
									<Validation.AvFeedback>
										Este campo é obrigatório
									</Validation.AvFeedback>
								</Validation.AvGroup>
								<Reactstrap.Row form>
									<Reactstrap.Col md={4}>
										<Validation.AvGroup>
											<Reactstrap.Input
												type="text"
												disabled={loading}
												placeholder="Nome completo"
												value={state.reportName}
												onChange={({
													target: { value },
												}) =>
													setState({
														reportName: value,
													})
												}
											/>
											<Validation.AvInput
												required
												type="hidden"
												name="completeName"
												id="completeNameInput"
												value={state.reportName}
											/>
											<Validation.AvFeedback>
												Este campo é obrigatório
											</Validation.AvFeedback>
										</Validation.AvGroup>
									</Reactstrap.Col>
									<Reactstrap.Col md={4}>
										<Validation.AvGroup>
											<Reactstrap.Input
												type="email"
												disabled={loading}
												placeholder="Email"
												value={state.reportEmail}
												onChange={({
													target: { value },
												}) =>
													setState({
														reportEmail: value,
													})
												}
											/>
											<Validation.AvInput
												required
												type="hidden"
												name="email"
												id="emailInput"
												value={state.reportEmail}
											/>
											<Validation.AvFeedback>
												Este campo é obrigatório
											</Validation.AvFeedback>
										</Validation.AvGroup>
									</Reactstrap.Col>
									<Reactstrap.Col md={4}>
										<Validation.AvGroup>
											<InputMask
												type="text"
												disabled={loading}
												placeholder="Telefone para contato"
												mask="(99) 99999-9999"
												className="form-control"
												value={state.reportPhone}
												onChange={({
													target: { value },
												}) =>
													setState({
														reportPhone: value,
													})
												}
											/>
											<Validation.AvInput
												required
												type="hidden"
												name="phone"
												id="phoneInput"
												value={state.reportPhone}
											/>
											<Validation.AvFeedback>
												Este campo é obrigatório
											</Validation.AvFeedback>
										</Validation.AvGroup>
									</Reactstrap.Col>
								</Reactstrap.Row>
								<Validation.AvGroup>
									<Reactstrap.Input
										disabled={loading}
										type="textarea"
										placeholder="Conte-nos a sua dúvida"
										value={state.reportMessage}
										onChange={({ target: { value } }) =>
											setState({ reportMessage: value })
										}
									/>
									<Validation.AvInput
										required
										type="hidden"
										name="text"
										id="textInput"
										value={state.reportMessage}
									/>
									<Validation.AvFeedback>
										Este campo é obrigatório
									</Validation.AvFeedback>
								</Validation.AvGroup>
							</>
						) : (
							<Styles.FeedbackMessage
								type={success ? 'success' : 'error'}
							>
								{success
									? 'Email enviado com sucesso'
									: 'Houve um erro ao tentar enviar o email'}
							</Styles.FeedbackMessage>
						)}

						<Styles.BtnCont>
							{success ? (
								<Reactstrap.Button
									type="button"
									color="info"
									onClick={() => {
										dispatch(helpActions.clearSuccess());
										setState(null);
									}}
								>
									ENVIAR OUTRO E-MAIL
								</Reactstrap.Button>
							) : (
								<>
									<Styles.ButtonSend
										color="info"
										type="submit"
										disabled={loading}
									>
										{error ? 'TENTAR NOVAMENTE' : 'ENVIAR'}
										<div>
											<VscLoading
												size={25}
												color="#fff"
											/>
										</div>
									</Styles.ButtonSend>

									{error && !loading && (
										<Styles.LinkCancel
											onClick={() => {
												dispatch(
													helpActions.clearError()
												);
												setState(null);
											}}
										>
											Cancelar envio
										</Styles.LinkCancel>
									)}
								</>
							)}
						</Styles.BtnCont>
					</Validation.AvForm>

					<Styles.Hr />
					<Styles.ContactTitle style={{ paddingTop: '20px' }}>
						<p>PERGUNTAS FREQUENTES</p>
					</Styles.ContactTitle>

					<Styles.Accordeon>
						<Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-1"
							>
								Como abrir arquivos shapefile?
							</a>

							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-1">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Esses arquivos são nativos dos
											programas da família ArcView/ArcGIS,
											da empresa norte-americana ESRI.
											Eles armazenam dados geográficos no
											formato vetorial, podendo ser do
											tipo ponto ou linha ou polígono.
										</Styles.Paragraph>
										<Styles.Paragraph>
											O shapefile é formado por um
											conjunto de arquivos de mesmo nome
											mas diferentes extensões. São
											arquivos obrigatórios os de extensão
											*.shp, *.shx e *.dbf. Sem a presença
											dessas três extensões em um mesmo
											diretório, os softwares não
											conseguem fazer a leitura.
										</Styles.Paragraph>
										<Styles.Paragraph>
											É possível visualizar os arquivos
											shapefile utilizando programas de
											SIG, como Quantum GIS e ArcGIS.
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem>

						{/* <Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-2"
							>
								Possuo dúvidas quanto a Pré-Consulta para Fins
								de Construção e/ou Pré-Consulta de Viabilidade
								para Instalação, como proceder?
							</a>

							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-2">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Entrar em contato com a Secretaria
											Municipal de Meio Ambiente e
											Desenvolvimento Urbano
										</Styles.Paragraph>
										<Styles.Paragraph>
											- SMDU, através do e-mail:&nbsp;
											<a href="mailto:viabilidade.plano@pmf.sc.gov.br">
												viabilidade.plano@pmf.sc.gov.br
											</a>
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem> */}

						{/* <Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-3"
							>
								Identifiquei um erro e/ou possuo dúvida em um
								documento emitido no geoportal, como proceder?
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-3">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Entrar em contato com as secretarias
											responsáveis:
										</Styles.Paragraph>

										<ul>
											<li>
												Pré-Consulta para Fins de
												Construção: Secretaria Municipal
												de Desenvolvimento Urbano,
												através do e-mail: &nbsp;
												<a href="mailto:viabilidade.plano@pmf.sc.gov.br">
													viabilidade.plano@pmf.sc.gov.br
												</a>
											</li>
											<li>
												Pré-Consulta de Viabilidade para
												Instalação: Secretaria Municipal
												de Desenvolvimento Urbano,
												através do e-mail: &nbsp;
												<a href="mailto:viabilidade.plano@pmf.sc.gov.br">
													viabilidade.plano@pmf.sc.gov.br
												</a>
											</li>
											<li>
												Memorial Descritivo: Instituto
												de Pesquisa e Planejamento
												Urbano de Florianópolis /
												Diretoria de Gestão Territorial,
												através do e-mail: &nbsp;
												<a href="mailto:dicgp.ipuf@pmf.sc.gov.br">
													dicgp.ipuf@pmf.sc.gov.br
												</a>
											</li>
											<li>
												Planta de Quadra: Instituto de
												Pesquisa e Planejamento Urbano
												de Florianópolis / Diretoria de
												Gestão Territorial, através do
												e-mail: &nbsp;
												<a href="mailto:dicgp.ipuf@pmf.sc.gov.br">
													dicgp.ipuf@pmf.sc.gov.br
												</a>
											</li>
										</ul>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem> */}

						<Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-4"
							>
								Quais os formatos dos arquivos vetoriais
								disponíveis para download
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-4">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Os arquivos são disponibilizados em
											formatos shapefile e em formato DXF,
											com coordenadas geográficas, datum
											SIRGAS2000. Cada pasta de download
											contém um arquivo PDF com metadados
											do arquivo baixado.
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem>

						{/* <Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-5"
							>
								O arquivo que estou procurando não está
								disponível para download, como proceder?
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-5">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Entre em contato com a Diretoria de
											Gestão Territorial do Instituto de
											Pesquisa e Planejamento Urbano de
											Florianópolis, através do e-mail
											&nbsp;
											<a href="mailto:dicgp.ipuf@pmf.sc.gov.br">
												dicgp.ipuf@pmf.sc.gov.br
											</a>
											.
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem> */}

						<Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-6"
							>
								O que são metadados?
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-6">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Metadados são informações de
											determinado dado. Os metadados são
											essenciais para organização e
											classificação de conjuntos de dados.
											Contemplam informações como: título,
											nome do arquivo, tipo de arquivo,
											geometria, escala de vetorização,
											Datum, Fuso UTM.
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem>

						<Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-7"
							>
								Como utilizar os geoserviços?
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-7">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Geoserviços é um conjunto de
											funcionalidades que permite o acesso
											e o uso aberto de informações
											geoespaciais por meio de um
											navegador de internet ou software de
											geoprocessamento. Com o intuito de
											automatizar e integrar os sistemas
											operacionais, disponibilizamos os
											geoserviços nos padrões OGG (Open
											Geospatial Consortium).
										</Styles.Paragraph>

										<Styles.Paragraph>
											Para a visualização de imagens
											(raster) geradas no formato JPEG,
											PNG, GIF ou SVG utilizar o protocolo
											WMS (Web Map Service). Para ter
											acesso aos dados vetoriais em função
											de consulta, utilizar o protocolo
											WFS (Web Feature Service).
										</Styles.Paragraph>

										<Styles.Paragraph>
											Faça aqui download do tutorial de
											como utilizar o geoserviços no QGIS.
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem>

						{/* <Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-8"
							>
								Mapa Interativo: Como escolher os Mapas
								Temáticos
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-8">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Os mapas temáticos têm uma
											importância fundamental, pois
											permite que se faça uma análise
											detalhada do município.
										</Styles.Paragraph>

										<Styles.Paragraph>
											O GeoPortal disponibiliza diversos
											mapas temáticos que podem ser
											visualizados facilmente. Os mapas
											temáticos são pré-estabelecidos por
											ser necessário o cruzamento do banco
											de dados e a parte gráfica. Dessa
											maneira, serão gerados de acordo com
											as informações existentes.
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem> */}

						{/* <Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-9"
							>
								Mapa Interativo: Como escolher as Ortofotos
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-9">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Uma ortofoto pode ser entendida como
											uma imagem fotográfica que passou
											por processos computacionais, nos
											quais foram eliminados o
											deslocamento em relação ao relevo e
											a distorção de geometria. Estas,
											podem ser utilizadas para auxiliar o
											usuário em localizações e análises
											espaciais.
										</Styles.Paragraph>

										<Styles.Paragraph>
											O geoprocessamento corporativo
											disponibiliza ortofotos de
											diferentes anos e escalas (Tabela
											01).
										</Styles.Paragraph>

										<Styles.Paragraph>
											Tabela 01 - Ano e Escala das
											ortofotos disponibilizadas
										</Styles.Paragraph>

										<Styles.TableWithin className="mb-3 ml-3">
											<thead>
												<tr>
													<th>ANO</th>
													<th>ESCALA</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>1938</td>
													<td>1:35000</td>
												</tr>
												<tr>
													<td>1957</td>
													<td>1:35000</td>
												</tr>
												<tr>
													<td>1977</td>
													<td>1:35000</td>
												</tr>
												<tr>
													<td>1994</td>
													<td>1:35000</td>
												</tr>
												<tr>
													<td>2000</td>
													<td>1:2000</td>
												</tr>
												<tr>
													<td>2001</td>
													<td>1:2000</td>
												</tr>
												<tr>
													<td>2002</td>
													<td>1:2000</td>
												</tr>
												<tr>
													<td>2002</td>
													<td>1:5000</td>
												</tr>
												<tr>
													<td>2003</td>
													<td>1:2000</td>
												</tr>
												<tr>
													<td>2007</td>
													<td>1:2000</td>
												</tr>
												<tr>
													<td>2007</td>
													<td>1:5000</td>
												</tr>
											</tbody>
										</Styles.TableWithin>

										<Styles.Paragraph>
											As ortofotos disponíveis no
											encontram-se georeferenciadas e
											podem ser visualizadas juntamente
											com os mapas temáticos. Conforme o
											ano da ortofoto e sua escala pode
											haver uma variação do grau de
											distorção em relação a base
											cartográfica.
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem> */}

						{/* <Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-10"
							>
								O que é a inscrição imobiliária?
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-10">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											A inscrição imobiliária, é o número
											da inscrição dos imóveis edificados
											ou não, cadastrados na Prefeitura de
											Municipal de Florianópolis,
											constituído por 17 (dezessete)
											dígitos.
										</Styles.Paragraph>
										<Styles.Paragraph>
											Segundo o Decreto nº 5.156, de 24 de
											setembro de 2007, &ldquo;Art. 1º Os
											imóveis localizados no Município de
											Florianópolis, edificados ou não,
											ainda que isentos ou imunes ao
											imposto, ficam obrigados à inscrição
											na Secretaria Municipal da Receita.
										</Styles.Paragraph>
										<Styles.Paragraph>
											§ 1º A cada unidade imobiliária
											autônoma corresponderá uma
											inscrição.
										</Styles.Paragraph>
										<Styles.Paragraph>
											§ 2º O número da inscrição
											imobiliária será constituído de 17
											(dezessete) dígitos que
											corresponderão, respectivamente, ao
											distrito de localização do imóvel,
											indicado por 2 (dois) dígitos; ao
											setor, indicado por 2 (dois)
											dígitos; à quadra, indicada por 3
											(três) dígitos; ao lote, indicado 4
											(quatro) dígitos, às unidades
											imobiliárias situadas no lote,
											indicadas por 3 (três) dígitos, além
											dos últimos 3 (três) dígitos de
											controle.
										</Styles.Paragraph>
										<Styles.Paragraph>
											§ 3º Proceder-se-á de ofício a
											alteração do número da inscrição
											imobiliária sempre que se fizer
											necessário, de modo a refletir a
											real posição do imóvel na quadra,
											devendo a inscrição anterior ser
											indicada na nova para que fique
											registrado o histórico
											cadastral.&ldquo;
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem> */}
{/* 
						<Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-11"
							>
								Não possuo inscrição imobiliária, como proceder?
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-11">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											Fazer a solicitação através do
											Pro-Cidadão, no link abaixo você
											encontra o passo a passo para esse
											procedimento.
										</Styles.Paragraph>

										<Styles.Paragraph>
											Solicitação de lançamento de IPTU
											&nbsp;
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="http://www.pmf.sc.gov.br/entidades/elm/index.php?pagina=servpagina&id=4302"
											>
												http://www.pmf.sc.gov.br/entidades/elm/index.php?pagina=servpagina&id=4302
											</a>
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem> */}

						<Styles.AccordeonItem>
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								id="common-questions-12"
							>
								O que é o levantamento planialtimétrico?
							</a>
							<div style={{ marginTop: '10px' }} />
							<Reactstrap.UncontrolledCollapse toggler="#common-questions-12">
								<Reactstrap.Card>
									<Reactstrap.CardBody>
										<Styles.Paragraph>
											O levantamento planialtimétrico é um
											levantamento topográfico que
											descreve o terreno por completo. O
											objetivo é uma planta topográfica
											que possua declividade e altimetria
											de uma determinada área. O
											Levantamento planialtimétrico deve
											conter memorial descritivo e ser
											acompanhado de sua respectiva
											Anotação de Responsabilidade Técnica
											– ART.
										</Styles.Paragraph>
									</Reactstrap.CardBody>
								</Reactstrap.Card>
							</Reactstrap.UncontrolledCollapse>
						</Styles.AccordeonItem>
					</Styles.Accordeon>
					<Styles.Hr />
					<Styles.ContactTitle style={{ paddingTop: '20px' }}>
						<p>ENTRE EM CONTATO</p>
					</Styles.ContactTitle>

					<Styles.ContactContent>
						<Reactstrap.Col
							md={4}
							className="d-flex justify-content-center align-items-start"
						>
							<div className="item">
								<p>TELEFONE</p>
								<a href="tel:03138392000">(31) 3839-2000</a>
							</div>
						</Reactstrap.Col>
						{/* <Reactstrap.Col
							md={3}
							className="d-flex justify-content-center align-items-start"
						>
							<div className="item">
								<p>EMAIL</p>
								<a href="mailto:dicgp.ipuf@pmf.sc.gov.br">
									dicgp.ipuf@pmf.sc.gov.br
								</a>
							</div>
						</Reactstrap.Col> */}
						<Reactstrap.Col
							md={4}
							className="d-flex justify-content-center align-items-start"
						>
							<div className="item">
								<p>ENDEREÇO</p>
								<a
									href="https://www.google.com/maps/place/Av.+Carlos+de+Paula+Andrade,+135+-+Centro,+Itabira+-+MG,+35900-206/data=!4m2!3m1!1s0xa5a1b0fb91d215:0x327b8830e408663?sa=X&ved=2ahUKEwjk-fydjp74AhUYr5UCHaY8BIwQ8gF6BAgFEAE"
									target="_blank"
									rel="noopener noreferrer"
								>
									Avenida Carlos de Paula Andrade, nº 135, Centro.
								</a>
							</div>
						</Reactstrap.Col>
						<Reactstrap.Col
							md={4}
							className="d-flex justify-content-center align-items-start"
						>
							<div className="item">
								<p>HORÁRIO DE FUNCIONAMENTO</p>
								<span>Segunda a Sexta - 07:00 às 17:00.</span>
							</div>
						</Reactstrap.Col>
					</Styles.ContactContent>
				</Styles.Box>
			</Styles.Content>
			<Footer />
		</>
	);
};

export default Help;
