/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { FiArrowRightCircle, FiArrowLeftCircle } from 'react-icons/fi';

import { Container, Content, ArticleCard, Image, Scroller } from './styles';

import news1 from '~/assets/img/news_01.jpg';
import news2 from '~/assets/img/news_02.jpg';
import news3 from '~/assets/img/news_03.jpg';
import news4 from '~/assets/img/news_04.jpg';
import news5 from '~/assets/img/news_05.jpg';
import news6 from '~/assets/img/news_06.jpg';

const ArticleCards = () => {
	const content = useRef(null);

	function scrollTo(element, to, duration) {
		if (duration <= 0) return;
		const difference = to - element.scrollLeft;
		const perTick = (difference / duration) * 10;

		setTimeout(() => {
			element.scrollLeft += perTick;
			if (element.scrollLeft === to) return;
			scrollTo(element, to, duration - 10);
		}, 10);
	}

	function handleScroll(isRight) {
		const { current } = content;
		if (isRight) {
			scrollTo(current, current.scrollLeft - 200, 100);
		} else {
			scrollTo(current, current.scrollLeft + 200, 100);
		}
	}

	return (
		<Container>
			<Scroller>
				<div onClick={() => handleScroll()}>
					<FiArrowLeftCircle size={25} />
				</div>
			</Scroller>
			<Scroller right>
				<div onClick={() => handleScroll(true)}>
					<FiArrowRightCircle size={25} />
				</div>
			</Scroller>
			<Content ref={content}>
				<ArticleCard className="container">
					<a href="https://www.itabira.mg.gov.br/detalhe-da-materia/info/educacao-resultado-preliminar-do-processo-de-extensao-de-carga-horaria/276304" target="_blank" rel="noopener noreferrer">
						<Image url={news1}>
							<h3>
								Educação: Resultado preliminar do Processo de Extensão de Carga Horária
							</h3>
						</Image>
					</a>
				</ArticleCard>

				<ArticleCard className="container">
					<a href="https://www.itabira.mg.gov.br/detalhe-da-materia/info/itabira-e-bicampea-em-gestao-em-premio-nacional-de-cidades-mineradoras/276305" target="_blank" rel="noopener noreferrer">
						<Image url={news2}>
							<h3>
								Itabira é bicampeã em Gestão em prêmio nacional de cidades mineradoras
							</h3>
						</Image>
					</a>
				</ArticleCard>

				<ArticleCard className="container">
					<a href="https://www.itabira.mg.gov.br/detalhe-da-materia/info/final-de-semana-contra-a-dengue-tera-mutirao-e-policlinica-aberta-para-atendimento-exclusivo/276307" target="_blank" rel="noopener noreferrer">
						<Image url={news3}>
							<h3>
								Final de semana contra a dengue terá mutirão e Policlínica aberta para atendimento exclusivo
							</h3>
						</Image>
					</a>
				</ArticleCard>

				<ArticleCard className="container">
					<a href="https://www.itabira.mg.gov.br/detalhe-da-materia/info/regularizacao-de-condominio-resulta-em-mais-de-r-800-mil-para-a-habitacao-social-em-itabira/276303" target="_blank" rel="noopener noreferrer">
						<Image url={news4}>
							<h3>
								Regularização de condomínio resulta em mais de R$ 800 mil para a habitação social em Itabira
							</h3>
						</Image>
					</a>
				</ArticleCard>

				<ArticleCard className="container">
					<a href="https://www.itabira.mg.gov.br/detalhe-da-materia/info/processo-seletivo-da-prefeitura-de-itabira-oferece-vagas-para-contratacao-temporaria/276302" target="_blank" rel="noopener noreferrer">
						<Image url={news5}>
							<h3>
								Processo seletivo da Prefeitura de Itabira oferece vagas para contratação temporária
							</h3>
						</Image>
					</a>
				</ArticleCard>

				<ArticleCard className="container">
					<a href="https://www.itabira.mg.gov.br/detalhe-da-materia/info/educacao-edital-de-vagas-para-extensao-de-carga-horaria-retificado/276309" target="_blank" rel="noopener noreferrer">
						<Image url={news6}>
							<h3>
								Educação: Edital de vagas para Extensão de Carga Horária Retificado
							</h3>
						</Image>
					</a>
				</ArticleCard>

			</Content>
		</Container>
	);
};

export default ArticleCards;
