import { combineReducers } from 'redux';

import downloads from './downloads/reducer';
import searchReducer from './search/reducer';
import help from './help/reducer';
import documentsEmission from './documentsEmission/reducer';

export default combineReducers({
	downloads,
	search: searchReducer,
	help,
	documentsEmission,
});
