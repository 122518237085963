import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Container, MiniCards, CardsTitle, Content } from './styles';
import NewsCard from '../NewsCard';
import { setState } from '~/store/modules/documentsEmission/actions';
import {
	FaRegMap,
	FaGlobeAmericas,
	FaRegFileAlt,
	FaCity,
	FaDownload,
} from 'react-icons/fa';

import {
	RiRoadMapLine
} from 'react-icons/ri'

import {
	GiGears
} from 'react-icons/gi'

const NewsCards = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<Container>
			<CardsTitle>ACESSE</CardsTitle>
			<Content>
				<MiniCards>
					<NewsCard
						color="#eeeeee"
						text='GESTÃO TERRITORIAL (SIT)'
						onClick={() => history.push('article/gestaoterritorial')}
						icon={<FaGlobeAmericas size={48} color="#22ade4"/>}
					/>
					<NewsCard
						color="#eeeeee"
						onClick={() => history.push('/map')}
						icon={<FaRegMap size={48} color="#22ade4"/>}
						text={
							<span style={{display:'flex',flexDirection:'column'}}>
								<span>MAPA</span>
								<span style={{color:'#22ade4'}}>
									INTERATIVO
								</span>
							</span>
						}
					>
					</NewsCard>
					<NewsCard
						color="#eeeeee"
						onClick={() => history.push('/services')}
						icon={<FaRegFileAlt size={48} color="#22ade4"/>}
						text={
							<span style={{display:'flex',flexDirection:'column'}}>
								<span>EMISSÃO DE</span>
								<span style={{color:'#22ade4'}}>
								DOCUMENTOS
								</span>
							</span>
						}						
						>					
					</NewsCard>
					<NewsCard
						color="#eeeeee"
						onClick={() => {
							dispatch(
								setState({
									presselected: 'consulta-instalacao',
								})
							);
							history.push('/services');
						}}
						icon={<FaCity size={48} color="#22ade4"/>}
						text={
							<span style={{display:'flex',flexDirection:'column'}}>
								<span>CONSULTA DE VIABILIDADE PARA</span>
								<span style={{color:'#22ade4'}}>
								INSTALAÇÃO
								</span>
							</span>
						}								
					>
					</NewsCard>					
					<NewsCard
						color="#eeeeee"
						text='GEOSERVIÇOS'
						onClick={() => history.push('/geoservices')}
						icon={<RiRoadMapLine size={48} color="#22ade4"/>}			
					/>
					<NewsCard
						color="#eeeeee"
						text={['DOWNLOADS']}
						onClick={() => history.push('/downloads')}
						icon={<FaDownload size={48} color="#22ade4"/>}
					/>
					<NewsCard
						color="#eeeeee"
						onClick={() =>
							history.push('/downloads/mapa_fisico_politico')
						}
						icon={<FaRegMap size={48} color="#22ade4"/>}
						text={
							<span style={{display:'flex',flexDirection:'column'}}>
								<span>MAPA FÍSICO</span>
								<span style={{color:'#22ade4'}}>
								POLÍTICO
								</span>
							</span>
						}								
					>					
					</NewsCard>
					<NewsCard
						color="#eeeeee"
						onClick={() => {
							const link = document.createElement('a');
							link.href =
								'https://www.itabira.mg.gov.br/servicos';
							link.target = '_blank';
							link.click();
						}}
						icon={<GiGears size={48} color="#22ade4"/>}
						text={
							<span style={{display:'flex',flexDirection:'column'}}>
								<span>PORTAL</span>
								<span style={{color:'#22ade4'}}>
								SERVIÇOS
								</span>
							</span>
						}							
					>
					</NewsCard>
					{/* <NewsCard
						color="#278AAE"
						text={['PRÉ-CONSULTA PARA FINS DE CONSTRUÇÃO']}
						onClick={() => {
							dispatch(
								setState({
									presselected: 'consulta-construcao',
								})
							);
							history.push('/services');
						}}
					/> */}
					{/* <NewsCard
						color="#278AAE"
						text={['CONSULTA AMBIENTAL']}
						onClick={() => {
							dispatch(
								setState({ presselected: 'consulta-ambiental' })
							);
							history.push('/services');
						}}
					/> */}
				</MiniCards>
			</Content>
		</Container>
	);
};

export default NewsCards;
