import styled from 'styled-components';

const DISPLAY_WIDTH = '630px';

export const Container = styled.div`
	background-color: rgba(255, 255, 255, 0.5);
	padding: 30px;
	width: 100%;
	margin-bottom: 20px;

	@media (max-width: ${DISPLAY_WIDTH}) {
		padding: 10px;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		padding: 30px;
	}
`;

export const MiniCards = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media (max-width: ${DISPLAY_WIDTH}) {
		justify-content: center;
	}
`;

export const CardsTitle = styled.h1`
	min-width: 100%;
	color: #595a5d;
	font-size: 17pt;
	margin-bottom: 7px;
`;

export const Content = styled.h1`
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
	width: 100%;
	max-width: 100%;
	color: #fff;
	align-items: flex-start;

	@media (max-width: ${DISPLAY_WIDTH}) {
		flex-direction: column;
	}
`;
