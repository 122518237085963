import styled, { css } from 'styled-components';
import { MdMenu } from 'react-icons/md';
import logo from '../../assets/img/logotipo.png';

import { DISPLAY_WIDTH } from '~/config/styles';

export const Container = styled.nav`
	box-shadow: 0px 7px 20px -6px rgba(0, 0, 0, 0.5);
	width: 100%;
	background-color: #EEEEEE;
	position: relative;
	z-index: 999;

	@media (max-width: ${DISPLAY_WIDTH}) {
		flex-wrap: wrap;
		padding: 5px 17px;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		padding: 8px 20px;
	}
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	z-index: 100;
`;

export const LogoContainer = styled.div`
	width: max-content;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-right: 10px;
	cursor: pointer;
	user-select: none;

	span {
		font-family: 'Asap', sans-serif;
		font-weight: bold;
		color: #59595C;
		-webkit-font-smoothing: antialiased;

		@media (max-width: ${DISPLAY_WIDTH}) {
			font-size: 13px;
			margin-top: 0px;
		}

		@media (min-width: ${DISPLAY_WIDTH}) {
			font-size: 15px;
			margin-top: 0px;
		}
	}
`;

export const Logo = styled.img.attrs({
	src: logo,
})`
	cursor: pointer;

	@media (max-width: ${DISPLAY_WIDTH}) {
		width: 130px;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		width: 160px;
	}
`;

export const Menu = styled.ul`
	width: 100%;
	display: flex;
	overflow: hidden;
	transition: background-color 200ms ease-in-out;
	background-color: #eee;
	z-index: 100;
	margin: 0;

	@media (max-width: ${DISPLAY_WIDTH}) {
		flex-direction: column;
		justify-content: center;
		height: 0px;
		position: absolute;
		left: 0;
		padding: 0px;
		box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.4);
		transition: height 200ms ease-in-out, padding 200ms ease-in-out,
			box-shadow 200ms ease-in-out;

		${(p) =>
			p.opened &&
			css`
				height: 212px !important;
				padding: 10px;
				transition: height 200ms ease-in-out, padding 200ms ease-in-out,
					box-shadow 200ms ease-in-out;
			`}
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		flex-direction: row;
		/* justify-content: flex-start; */
		justify-content: flex-end;
	}
`;

export const MenuContainer = styled.div`
	width: 100%;
	display: flex;
`;

export const RespContainer = styled.div`
	@media (max-width: ${DISPLAY_WIDTH}) {
		min-width: 100%;
		display: flex;
		justify-content: space-between;
	}
`;

export const MenuItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;
	transform: scale(1);
	transition: background-color 250ms ease-in-out, transform 250ms ease-in-out,
	box-shadow 250ms ease-in-out;
	font-weight: bold;

	@media (max-width: ${DISPLAY_WIDTH}) {
		cursor: pointer;

		&:hover {
			background-color: #fbae17;
			transform: scale(1.01);
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
			transition: background-color 250ms ease-in-out,
				transform 250ms ease-in-out, box-shadow 250ms ease-in-out;

			a {
				color: #fff;
				transition: color 200ms ease-in-out;
			}
		}
	}

	a {
		text-decoration: none;
		color: ${(p) => (p.active ? '#fbae17' : '#595a5d')};
		text-align: center;
		padding: 2px;
		border-bottom: 2px solid transparent;
		transition: border-bottom 150ms ease-in-out, color 200ms ease-in-out;

		${({ highlight, active }) =>
			highlight &&
			!active &&
			css`
				border-bottom: 2px solid #2aade2;
				background-color: rgb(42, 173, 226, 0.07);
				transition: border-bottom 150ms ease-in-out;
			`}
			
		${({ highlight, active }) =>
			!highlight &&
			active &&
			css`
				border-bottom: 2px solid #fbae17;
				background-color: rgb(251, 174, 23, 0.07);
				transition: border-bottom 150ms ease-in-out;
			`}

		&:hover {
			@media (min-width: ${DISPLAY_WIDTH}) {
				background-color: transparent;
				border-bottom: 2px solid #fbae17;
				transition: border-bottom 150ms ease-in-out,
					background-color 150ms ease-in-out;
			}
		}
	}
`;

export const MenuIcon = styled(MdMenu).attrs({
	size: 40,
	color: '#2aade2',
})`
	cursor: pointer;

	@media (max-width: ${DISPLAY_WIDTH}) {
		display: block;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		display: none;
	}
`;
