import React from 'react';
import { FiArrowRightCircle } from 'react-icons/fi';

import { Container } from './styles';

const NewsCard = ({ color, text = [], onClick, comingSoon = false, children, icon }) => {
	return (
		<Container color={color} onClick={onClick || (() => {})}>
			{!comingSoon && <FiArrowRightCircle size={22} />}
			{comingSoon && <span>Em Breve</span>}
			<div>
				<h2>
					{icon}
					{text}
				</h2>
			</div>
		</Container>
	);
};

export default NewsCard;
